<template>
    <v-container>
        <div class="table-options">
            <v-btn class="my-2" dark small color="#027a00" @click="createUser">
                <v-icon dark>mdi-plus</v-icon>Create
            </v-btn>
        </div>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="users"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td @click="handleClick('show', props.item)">{{ props.item.email }}</td>
                    <td @click="handleClick('show', props.item)">
                        <div v-if="props.item.partner">
                            {{ props.item.partner.title }}
                        </div>
                    </td>
                    <td>
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import userService from '@/services/user-service'
    import DeleteButton from '@/components/DeleteButton'
    export default {
        name: 'TeamRoute',
        components: { DeleteButton },
        data: () => {
            return {
                users: [],
                headers: [{
                              text: 'Email',
                              value: 'email',
                              align: 'left',
                              sortable: true
                          },
                          { text: 'Partner', value: 'partner' }]
            }
        },
        created() {
            this.fetchAllUsers()
        },
        methods: {
            handleClick(mode, item) {
                this.$router.push(`/users/${item.id}/${mode}`)
            },
            onDelete(item) {
                userService.deleteUserById(item.id).then(() => {
                    this.fetchAllUsers()
                })
            },
            createUser() {
                this.$router.push(`/users/create`)
            },
            fetchAllUsers() {
                userService.getAllUsers().then(resp => {
                    this.users = resp.data.data
                })
            }
        }
    }
</script>

<style scoped>
.search {
    max-width: 300px;
}
.table-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
